import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AboutPage from "./pages/about";
import HomePage from "./pages/home";
import VideoStream from "./pages/meeting";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <HomePage />
      </div>
    ),
  },
  {
    path: "/about",
    element: (
      <div>
        <AboutPage />
      </div>
    ),
  },
  {
    path: "*",
    element: (
      <div>
        <VideoStream />
      </div>
    ),
  },
]);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
