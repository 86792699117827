import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./notFound.module.scss";
export const VideoStream = () => {
  const conferenceContainerId = "conference-container-id";
  const domain = "unimeet-mb-1-console.myunisearch.com";
  const location = useLocation();
  const history = useNavigate();
  const [meetingDetails, setMeetingDetails] = useState();
  const [loading, setLoading] = useState(false);
  const getMeetingDetails = async () => {
    setLoading(true);
    try {
      await axios
        .get(
          `https://devapi.myunisearch.com/api/v1/public/uni-meeting${location?.pathname}`
        )
        .then(function (response) {
          setMeetingDetails(response?.data?.data);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMeetingDetails();
  }, []);

  const [jitsi, setJitsi] = useState({});
  useEffect(() => {
    document.title = `UniMeet`;
  }, []);

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });
    const script = document.createElement("script");
    script.src = "https://unimeet-mb-1-console.myunisearch.com/external_api.js";
    script.async = true;
    script.onload = () => {
      resolveLoadJitsiScriptPromise(true);
    };
    document.body.appendChild(script);
    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const options = {
      roomName: meetingDetails?.meetSlug ?? "Unisearch-Meeting",
      roomPassword: meetingDetails?.password ?? "",
      configOverwrite: {
        disableDeepLinking: true,
        prejoinPageEnabled: false,
        disableThirdPartyRequests: false,
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
      },

      parentNode: document.getElementById(conferenceContainerId),
      userInfo: {
        moderator: meetingDetails?.isHost ?? false,
        displayName: meetingDetails?.displayName,
        email: meetingDetails?.hostMail,
      },
      moderator: meetingDetails?.isHost ?? false,
    };

    const _jitsi = new window.JitsiMeetExternalAPI(domain, options);

    _jitsi.addEventListener("participantRoleChanged", function (event) {
      if (event.role === "moderator") {
        _jitsi.executeCommand("password", options.roomPassword);
      }
    });
    _jitsi.addEventListener("readyToClose", function () {
      setTimeout(() => {
        history("/");
      }, 3000);
    });
    setJitsi(_jitsi);
  };

  useEffect(() => {
    if (
      meetingDetails &&
      new Date(meetingDetails?.endTime).getTime() > new Date().getTime()
    ) {
      initialiseJitsi();

      window.addEventListener("beforeunload", function (e) {
        // alert(e);
      });
      return () => jitsi?.dispose?.();
    }
  }, [meetingDetails]);

  return (
    <Fragment>
      {!loading && meetingDetails ? (
        <div>
          {new Date(meetingDetails?.endTime).getTime() >
          new Date().getTime() ? (
            <div
              id={conferenceContainerId}
              style={{ height: "100vh", width: "100%" }}
              className={styles.iframeLoad}
            >
              <div className={styles.loading}>Loading&#8230;</div>
            </div>
          ) : (
            <section className="h-screen relative">
              <div className={styles.wrapper}>
                <img
                  className={styles.illustration}
                  src={"assets/custom404.svg"}
                />
                <p>
                  We can’t find meeting that <br /> you’re looking for.
                </p>
              </div>
            </section>
          )}
        </div>
      ) : (
        <div className={styles.loading}>Loading&#8230;</div>
      )}
    </Fragment>
  );
};

export default VideoStream;
