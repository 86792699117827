import React, { Fragment } from "react";

const HomePage = () => {
  React.useEffect(() => {
    document.title = `UniMeet`;
  }, []);
  return (
    <>
      <section className="h-screen relative">
        <div className="w-full h-full z-10 absolute left-0 top-0">
          <img
            src="./assets/welcome-bg.png"
            alt="welcome"
            className="w-full h-full "
          />
        </div>
        <div className="h-full flex justify-center items-center p-6">
          <h1 className="text-[36px] md:text-[44px] lg:text-[56px] font-semibold z-50 mt-[-20vh] text-center">
            Welcome To<span className="text-[#f47f20]"> UniMeet</span>{" "}
          </h1>
        </div>
      </section>

      {/* when passcode then this screen will be shown */}
      {/* <Fragment>
        <section className="bg-[#ebeef6]">
          <div className="container mx-auto px-6">
            <div className="flex justify-start items-center py-[20px]">
              <div>
                <h3 className="text-[20px] font-medium text-[#] ">
                  Meeting Title:
                  <span className="text-[18px] font-bold">
                    {" "}
                    Suitable Time to get Married
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="conatiner px-6">
            <div className="flex justify-center items-center mt-[30vh] max-w-[700px] w-full mx-auto">
              <form className="w-full">
                <div className="flex gap-8 w-full">
                  <input
                    type="text"
                    className="bg-[#f7f7fe] outline-none py-4 px-5 w-4/5 rounded-[5px]"
                    placeholder="Enter Passcode"
                  />
                  <button
                    type="submit"
                    className="bg-[#f47f20] rounded-[5px] text-white font-semibold px-8 py-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section>
          <div className="w-full" style={{ height: "calc(100vh - 100px)" }}>
            <img
              src="./assets/video-conference-img4.webp"
              alt="video-conference"
              className="w-full h-full object-cover"
            />
          </div>
        </section>
      </Fragment> */}
    </>
  );
};

export default HomePage;
